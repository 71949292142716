<template>
  <div id="schedule-main">
    <h1>Defauls Deschedule</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
